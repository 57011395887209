import React from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';

import css from './SupportedSection.module.css';

import logo1 from './img/logo1.png';
import logo2 from './img/logo2.png';
import logo3 from './img/logo3.png';
import logo4 from './img/logo4.png';

const SupportedSection = () => {
  return (
    <div className={css.wrapper}>
      <h2 className={css.title}>
        <FormattedMessage id="LandingPage.supportedTitle" />
      </h2>
      <div className={css.container}>
        <div>
          <a href="https://www.ekd.de/">
            <img className={css.logo} src={logo1} alt="" />
          </a>
          <a href="https://www.ekd.de/kirche-und-sport-48549.htm">
            <img className={css.logo} src={logo2} alt="" />
          </a>
        </div>
        <div>
          <a href="https://www.churchpool.com/">
            <img className={css.logo} src={logo3} alt="" />
          </a>
          <a href="https://www.cvjm.de/">
            <img className={css.logo} src={logo4} alt="" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SupportedSection;
