import React from 'react';
import { NamedLink } from '../../../../components';
import { FormattedMessage } from '../../../../util/reactIntl';

import css from './InfoBoxes.module.css';

const InfoBoxes = () => {
  return (
    <div className={css.box2}>
      <div className={css.box2Text}>
        <FormattedMessage id="LandingPage.infoText" />
      </div>
      <NamedLink className={css.link} name="HowItWorksPage">
        <FormattedMessage id="LandingPage.infoButton" />
      </NamedLink>
    </div>
  );
};

export default InfoBoxes;
