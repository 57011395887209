import React, { useState } from 'react';
import { FormattedMessage } from '../../../../util/reactIntl';

import css from './ReadMoreSection.module.css';

const AngleUpIcon = () => {
  return (
    <span className={css.iconWrapper}>
      <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512">
        <path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z" />
      </svg>
    </span>
  );
};

const AngleDownIcon = () => {
  return (
    <span className={css.iconWrapper}>
      <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512">
        <path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
      </svg>
    </span>
  );
};

const ReadMoreSection = () => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className={css.wrapper}>
      <div className={css.container}>
        <h2 className={css.title}>
          <FormattedMessage id="LandingPage.showMoreTitle" />
        </h2>
        <p className={css.text}>
          {showMore ? (
            <>
              <FormattedMessage id="LandingPage.showMoreTextPart1" />
              <br />
              <FormattedMessage id="LandingPage.showMoreTextPart2" />
              <br />
              <FormattedMessage id="LandingPage.showMoreTextPart3" />
            </>
          ) : (
            <FormattedMessage id="LandingPage.showLessText" />
          )}
        </p>

        <button className={css.expandButton} onClick={toggleShowMore}>
          {showMore ? (
            <FormattedMessage id="LandingPage.showLessButton" />
          ) : (
            <FormattedMessage id="LandingPage.showMoreButton" />
          )}
          {showMore ? <AngleUpIcon /> : <AngleDownIcon />}
        </button>
      </div>
    </div>
  );
};

export default ReadMoreSection;
